import { type AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import {
  type EditorPlatformApp,
  type InitialAppData,
} from '@wix/platform-editor-sdk';
import { type EditorAppContext } from './types';
import {
  globalDesignPresetChanged,
  widgetGfppClicked,
} from './editor/event-listeners';

const createEditorApp = (): EditorPlatformApp<AppManifestBuilder> => {
  let context: EditorAppContext;

  return {
    editorReady: async (editorSDK, appToken, options) => {
      context = { editorSDK, appToken };

      await Promise.all([
        widgetGfppClicked({ context, options }),
        globalDesignPresetChanged(context),
      ]);
    },
    getAppManifest: async (options) => {
      const { appManifestBuilder } = options;
      const baseManifest = await fetchWidgetsStageData({
        initialAppData: options as unknown as InitialAppData,
        editorSDK: context.editorSDK,
      });
      return appManifestBuilder.withJsonManifest(baseManifest).build();
    },
    exports: () => ({
      editor: {
        getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
          const preset =
            await context.editorSDK.application.appStudioWidgets.getPreset(
              context.appToken,
              {
                componentRef: widgetRef,
              },
            );
          return preset.style;
        },
      },
    }),
  };
};

const editorApp = createEditorApp();

export const editorReady = editorApp.editorReady;
export const getAppManifest = editorApp.getAppManifest;
export const exports = editorApp.exports;
