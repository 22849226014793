import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { ROLES } from '../../constants';
import { getWidgetPresets } from '../../editor/presets';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Blog Category Header',
    nickname: 'Category headerWidget',
  });

  [ROLES.image, ROLES.title, ROLES.description].forEach((role) =>
    widgetBuilder.configureConnectedComponents(
      role,
      (connectedComponentsBuilder) =>
        connectedComponentsBuilder.behavior().set({
          preventHide: false,
        }),
    ),
  );

  widgetBuilder.configureWidgetPresets((widgetDesignBuilder) =>
    widgetDesignBuilder.setPresets(getWidgetPresets()),
  );
};
