import { type ComponentRef } from '@wix/platform-editor-sdk';
import { elementsData } from './elements-panel-data';
import { type EditorAppContext } from '../types';

type OpenElementsPanelParams = {
  context: EditorAppContext;
  widgetRef: ComponentRef;
};

export const openElementsPanel = ({
  context: { editorSDK, appToken },
  widgetRef,
}: OpenElementsPanelParams) => {
  const getCollapsedRefComponentByRole = async (role: string) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors(appToken, {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        appToken,
        {
          componentRef: widgetRefHost,
          // @ts-expect-error
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );

    return collapsedRefComponent[0]?.componentRef;
  };

  editorSDK.editor.openElementsPanel(appToken, {
    widgetRef,
    categoriesData: [],
    elementsData,
    addComponentHandler: async ({ role }, compRef) => {
      const componentRef =
        compRef || (await getCollapsedRefComponentByRole(role));

      await editorSDK.components.refComponents.expandReferredComponent(
        appToken,
        {
          componentRef,
        },
      );
    },
    removeComponentHandler: async (componentRef) => {
      const type = await editorSDK.components.getType(appToken, {
        componentRef,
      });
      const compToHide = type.includes('AppWidget')
        ? (
            await editorSDK.components.getAncestors(appToken, { componentRef })
          )[0]
        : componentRef;

      await editorSDK.components.refComponents.collapseReferredComponent(
        appToken,
        {
          componentRef: compToHide,
        },
      );
    },
  });
};
