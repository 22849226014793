import classic from '../assets/images/classic.png';
import sidebyside from '../assets/images/sidebyside.png';
import overlay from '../assets/images/overlay.png';

export const PRESETS = {
  classic: 'variants-lz15fc10',
  overlay: 'variants-lz2fudbq',
  sideBySide: 'variants-lz2l494r',
};

export const getWidgetPresets = () => [
  {
    id: PRESETS.classic,
    connectedMobilePresetId: 'variants-lz2e4402',
    src: classic,
  },
  {
    id: PRESETS.overlay,
    connectedMobilePresetId: 'variants-lz2kmdco',
    src: overlay,
  },
  {
    id: PRESETS.sideBySide,
    connectedMobilePresetId: 'variants-lz2e4402',
    src: sidebyside,
  },
];
