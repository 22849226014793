import { ROLES } from '../constants';

const COMPS_DATA = {
  image: { role: ROLES.image, label: 'Image' },
  title: { role: ROLES.title, label: 'Title' },
  description: { role: ROLES.description, label: 'Description' },
};

export const elementsData = [
  {
    label: COMPS_DATA.image.label,
    identifier: { role: COMPS_DATA.image.role },
    index: 0,
  },
  {
    label: COMPS_DATA.title.label,
    identifier: { role: COMPS_DATA.title.role },
    index: 1,
  },
  {
    label: COMPS_DATA.description.label,
    identifier: { role: COMPS_DATA.description.role },
    index: 2,
  },
];
